@import 'colors';

.swiper-button-next {
  display: inline-block !important;
  width: 3em !important;
  height: 3em !important;
  border-radius: 50% !important;
  color: #a0aec3 !important;
  background-color: #f4f7fa !important;
  margin-right: 1.5em !important;
  cursor: pointer !important;
  top: 37% !important;
  right: 0 !important;
  z-index: 1000 !important;
}

.swiper-button-next:after {
  content: url("data:image/svg+xml,%3Csvg width='8' height='17' viewBox='0 0 8 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 15.5312L0.999999 8.53125L7 1.53125' stroke='%23A0AEC3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
  position: absolute !important;
  left: 0.5em !important;
  top: 0.8em !important;
  width: 8px !important;
  height: 17px !important;
  -moz-transform: rotate(180deg) !important;
  -webkit-transform: rotate(180deg) !important;
  transform: rotate(180deg) !important;
}

.swiper-button-prev {
  width: 3em !important;
  height: 3em !important;
  border-radius: 50% !important;
  color: #a0aec3 !important;
  background-color: #f4f7fa !important;
  margin-right: 1.5em !important;
  cursor: pointer !important;
  top: 37% !important;
  left: 2% !important;
  z-index: 1000 !important;
}

.swiper-button-prev:after {
  content: url("data:image/svg+xml,%3Csvg width='8' height='17' viewBox='0 0 8 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 15.5312L0.999999 8.53125L7 1.53125' stroke='%23A0AEC3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
  position: absolute !important;
  left: 0.45em !important;
  top: -0.1em !important;
  width: 8px !important;
  height: 17px !important;
}

.swiper-pagination-bullet {
  background-color: $colorText400  !important;
}

.swiper-pagination-bullet-active {
  background-color: $colorPrimary700 !important;
}
